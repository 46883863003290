<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo mb-5 mb-lg-0">
        <vuexy-logo />

        <!-- <h2 class="brand-text text-primary ml-1">
          VET
        </h2> -->
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="6"
        xl="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Register V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- PADDING P-LG-2 DEVE ESSERE UGUALE AL LOGIN -->
      <!-- Register-->
      <b-col
        lg="6"
        xl="4"
        class="d-flex align-items-center auth-bg p-lg-5 mt-2 mt-lg-0"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1 mt-5 mt-lg-0"
          >
            Registra un nuovo account 🚀
          </b-card-title>

          <!-- form -->
          <validation-observer ref="registerForm">
            <b-form class="auth-register-form mt-2">
              <!-- name/surname -->
              <b-row>
                <b-col
                  lg="12"
                >
                  <b-form-group
                    label="Il tuo Ruolo *"
                    label-for="register-ruolo"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="ruolo"
                      rules="required"
                    >
                      <v-select
                        v-model="selectedRole"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="title"
                        :clearable="false"
                        :options="rolesOptions"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  lg="6"
                >
                  <b-form-group
                    label="Nome *"
                    label-for="register-name"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="name"
                      rules="required"
                    >
                      <b-form-input
                        id="register-name"
                        v-model="name"
                        name="register-name"
                        :state="errors.length > 0 ? false:null"
                        placeholder="nome"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  lg="6"
                >
                  <b-form-group
                    label="Cognome *"
                    label-for="register-name"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="cognome"
                      rules="required"
                    >
                      <b-form-input
                        id="register-cognome"
                        v-model="cognome"
                        name="register-cognome"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Cognome"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <!-- FINE name/surname -->

              <!-- INIZIO EMAIL/ -->
              <b-row>
                <b-col
                  lg="12"
                >
                  <!-- email -->
                  <b-form-group
                    label="Email *"
                    label-for="register-email"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Email"
                      rules="required|email"
                    >
                      <b-form-input
                        id="register-email"
                        v-model="email"
                        name="register-email"
                        :state="errors.length > 0 ? false:null"
                        placeholder="john@example.com"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                </b-col>
                <b-col
                  lg="12"
                >
                  <b-form-group>
                    <div class="d-flex justify-content-between">
                      <label for="register-password">Password *</label>
                    </div>
                    <validation-provider
                      #default="{ errors }"
                      name="Password *"
                      vid="password"
                      :rules="{ required: true, regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/ }"
                    ><!-- rules="required|min:8|max:35|digits:1" -->
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid':null"
                      >
                        <b-form-input
                          id="register-password"
                          v-model="password"
                          :state="errors.length > 0 ? false:null"
                          class="form-control-merge"
                          :type="passwordFieldType"
                          name="register-password"
                          placeholder="Password"
                        />
                        <b-input-group-append is-text>
                          <feather-icon
                            class="cursor-pointer"
                            :icon="passwordToggleIcon"
                            @click="togglePasswordVisibility"
                          />
                        </b-input-group-append>
                      </b-input-group>
                      <small
                        v-if="errors[0]"
                        class="text-danger"
                      >La password deve essere minimo di 8 caratteri di cui: 1 carattere speciale, 1 numero e 1 carattere Maiuscolo</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  lg="6"
                >
                  <b-form-group
                    :label="selectedRole.value === 'Proprietario' ? 'Regione Sociale *' : 'Regione Sociale'"
                    label-for="register-regione_sociale"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Regione Sociale"
                      :rules="{required: selectedRole.value === 'Proprietario' ? true : false }"
                    >
                      <b-form-input
                        id="register-regione_sociale"
                        v-model="regione_sociale"
                        name="register-regione_sociale"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Regione Sociale"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  lg="6"
                >
                  <b-form-group
                    label="Cellulare *"
                    label-for="register-cellulare"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="cellulare"
                      rules="required"
                    >
                      <b-form-input
                        id="register-cellulare"
                        v-model="cellulare"
                        name="register-cellulare"
                        :state="errors.length > 0 ? false:null"
                        placeholder="3801257813"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  lg="6"
                >
                  <b-form-group
                    label="Lingua *"
                    label-for="leanguage-i18n"
                  >
                    <v-select
                      v-model="localeSelected"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="locales"
                      :clearable="false"
                      label="nameLocale"
                    >
                      <template #option="{ locale, nameLocale, img }">
                        <b-img
                          :src="img"
                          height="14px"
                          width="22px"
                          :alt="locale+nameLocale"
                        />
                        <span> {{ nameLocale }}</span>
                      </template>
                    </v-select>
                  </b-form-group>
                </b-col>
              </b-row>
              <validation-provider
                #default="{ errors }"
                name="Termini e condizioni"
                rules="required"
              >
                <b-form-checkbox
                  id="register-privacy-policy"
                  v-model="statusTerms"
                  value="Y"
                  name="termsandconditions"
                  class="my-1"
                  :class="{ 'text-white': errors[0] }"
                >
                  Accetto
                  <b-link>termini & condizioni </b-link>*
                </b-form-checkbox>
                <!--<small class="text-danger mt-25">{{ errors[0] }}</small>-->
              </validation-provider>
              <b-form-checkbox
                id="register-marketing-checkbox"
                v-model="statusMarketing"
                name="marketing"
                class="mb-1"
                value="Y"
                unchecked-value="N"
              >
                Accetto
                <b-link>Profilazione Marketing</b-link>
              </b-form-checkbox>
              <b-button
                variant="primary"
                block
                type="submit"
                @click.prevent="validationForm"
              >
                Sign up
              </b-button>
            </b-form>
          </validation-observer>
          <p class="text-start mt-2">
            <span>* Campi Obbligatori</span>
          </p>
          <!-- divider -->
          <div class="divider my-2">
            <div class="divider-text">
              oppure
            </div>
          </div>

          <div class="auth-footer-btn d-flex justify-content-center">
            <p class="text-center mt-2">
              <span>Hai già un account?</span>
              <b-link :to="{name:'auth-login-v2'}">
                <span>&nbsp;Accedi</span>
              </b-link>
            </p>
          </div>
        </b-col>
      </b-col>
    <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BButton, BForm, BFormCheckbox, BFormGroup, BFormInput, BImg, BCardTitle, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import axios from 'axios'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { filterErrorResponseApi } from '@core/utils/filter'
import store from '@/store/index'

export default {
  components: {
    VuexyLogo,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    vSelect,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      // select ruoli
      selectedRole: { title: 'Professionista Veterinario', value: 'Proprietario' },
      rolesOptions: [{ title: 'Professionista Veterinario', value: 'Proprietario' }, { title: 'Proprietario Animale', value: 'Proprietario Animale' }],
      // errori
      errorsResponse: [],
      errorsResponse2: [],
      alertHTML: '',
      statusTerms: '',
      statusMarketing: '',
      name: '',
      cognome: '',
      // email: '',
      ruolo: '',
      regione_sociale: '',
      partita_iva: '',
      codice_fiscale: '',
      cellulare: '',
      piano: 'FREE',
      // username: '',
      // userEmail: '',
      password: '',
      sideImg: require('@/assets/images/pages/register-v2.svg'),
      // validation
      required,
      email,
      localeSelected:
      {
        locale: 'it',
        img: require('@/assets/images/flags/it.png'),
        nameLocale: 'Italiano',
      },
      locales: [
        {
          locale: 'it',
          img: require('@/assets/images/flags/it.png'),
          nameLocale: 'Italiano',
        },
        {
          locale: 'en',
          img: require('@/assets/images/flags/en.png'),
          nameLocale: 'English',
        },
        {
          locale: 'fr',
          img: require('@/assets/images/flags/fr.png'),
          nameLocale: 'French',
        },
        {
          locale: 'de',
          img: require('@/assets/images/flags/de.png'),
          nameLocale: 'German',
        },
        {
          locale: 'pt',
          img: require('@/assets/images/flags/pt.png'),
          nameLocale: 'Portuguese',
        },
      ],
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/register-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
    localeNow() {
      return this.$i18n.locale
    },
  },
  mounted() {
    localize(this.localeNow)
    // this.recaptcha()
    // this.$refs.registerForm.reset()
  },
  methods: {
    async recaptcha() {
      // (optional) Wait until recaptcha has been loaded.
      await this.$recaptchaLoaded()

      // Execute reCAPTCHA with action "login".
      // const token = await this.$recaptcha('login')

      // Do stuff with the received token.
    },
    validationForm() {
      // console.log(this.statusTerms)
      // console.log(this.statusMarketing)
      /*
      console.log('lingua', this.$i18n.locale)
      console.log('name', this.name)
      console.log('cognome', this.cognome)
      console.log('ruolo', this.selectedRole.value)
      console.log('ragione_sociale', this.ragione_sociale)
      console.log('password', this.password)
      console.log('cellulare', this.cellulare)
      console.log('email', this.email)
      console.log('piano', this.piano)
      console.log('lingua', this.localeSelected)
      */
      this.$refs.registerForm.validate().then(async success => {
        if (success) {
          await axios.post(process.env.VUE_APP_URL_API_SERVER_REGISTER, {
            name: this.name,
            cognome: this.cognome,
            ruolo: this.selectedRole.value,
            ragione_sociale: this.regione_sociale,
            password: this.password,
            cellulare: this.cellulare,
            email: this.email,
            piano: this.piano,
            lingua: this.localeSelected.locale,
            TERMINI_E_CONDIZIONI: this.statusTerms === 'Y' ? 'Y' : 'N',
            MARKETING: this.statusMarketing === 'Y' ? 'Y' : 'N',
          })
            .then(res => {
              // this.errorsResponse = { ...res.data.messaggio }
              // this.prepareErrorsResponseInArray({ ...res.data.messaggio })
              // console.log('errors', this.errorsResponse)
              // console.log(res)
              if (res.data.esito === 'OK') {
                this.$swal({
                  title: res.data.messaggio,
                  text: 'Adesso puoi effettuare il login!',
                  icon: 'success',
                  timer: 3000,
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              }
              if (res.data.esito === 'KO') {
                // OOOK
                // this.prepareErrorsResponseInArray(res.data.messaggio)
                // OOK FINE
                this.errorsResponse = filterErrorResponseApi(res.data.messaggio)
                // console.log('2', this.errorsResponse)
                // const prova2 = this.errorsResponse.map(item => `<p style="text-align:start!important;>${item}</p>`).join('')
                this.$swal.fire({
                  title: '<strong><u>Errore</u></strong>',
                  icon: 'error',
                  html: this.errorsResponse.map(item => `<p class="text-left">${item}</p>`).join(''),
                  showCloseButton: true,
                  showCancelButton: true,
                  focusConfirm: false,
                  customClass: {
                    confirmButton: 'btn btn-primary mr-2',
                  },
                })
              }
              // console.log(this.errorsResponse)
            })
            .catch(e => {
              // console.log(e)
              if (e) {
                this.$swal({
                  title: e,
                  text: 'err',
                  icon: 'error',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              }
            })
        }
      })
    },
    prepareErrorsResponseInArray(errors) {
      // console.log('errors', errors)
      // eslint-disable-next-line no-restricted-syntax
      // eslint-disable-next-line no-unused-vars
      // eslint-disable-next-line no-restricted-syntax
      for (const [, value] of Object.entries(errors)) {
        this.errorsResponse.push(`${value}`)
      }
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/pages/page-auth.scss';
</style>
<style scoped>
.text-danger {
  font-size: 10px;
}
::marker {
  content:none
}
</style>
