var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-link',{staticClass:"brand-logo mb-5 mb-lg-0"},[_c('vuexy-logo')],1),_c('b-col',{staticClass:"d-none d-lg-flex align-items-center p-5",attrs:{"lg":"6","xl":"8"}},[_c('div',{staticClass:"w-100 d-lg-flex align-items-center justify-content-center px-5"},[_c('b-img',{attrs:{"fluid":"","src":_vm.imgUrl,"alt":"Register V2"}})],1)]),_c('b-col',{staticClass:"d-flex align-items-center auth-bg p-lg-5 mt-2 mt-lg-0",attrs:{"lg":"6","xl":"4"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('b-card-title',{staticClass:"font-weight-bold mb-1 mt-5 mt-lg-0",attrs:{"title-tag":"h2"}},[_vm._v(" Registra un nuovo account 🚀 ")]),_c('validation-observer',{ref:"registerForm"},[_c('b-form',{staticClass:"auth-register-form mt-2"},[_c('b-row',[_c('b-col',{attrs:{"lg":"12"}},[_c('b-form-group',{attrs:{"label":"Il tuo Ruolo *","label-for":"register-ruolo"}},[_c('validation-provider',{attrs:{"name":"ruolo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","clearable":false,"options":_vm.rolesOptions},model:{value:(_vm.selectedRole),callback:function ($$v) {_vm.selectedRole=$$v},expression:"selectedRole"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"6"}},[_c('b-form-group',{attrs:{"label":"Nome *","label-for":"register-name"}},[_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-name","name":"register-name","state":errors.length > 0 ? false:null,"placeholder":"nome"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"6"}},[_c('b-form-group',{attrs:{"label":"Cognome *","label-for":"register-name"}},[_c('validation-provider',{attrs:{"name":"cognome","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-cognome","name":"register-cognome","state":errors.length > 0 ? false:null,"placeholder":"Cognome"},model:{value:(_vm.cognome),callback:function ($$v) {_vm.cognome=$$v},expression:"cognome"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"lg":"12"}},[_c('b-form-group',{attrs:{"label":"Email *","label-for":"register-email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-email","name":"register-email","state":errors.length > 0 ? false:null,"placeholder":"john@example.com"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"12"}},[_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{attrs:{"for":"register-password"}},[_vm._v("Password *")])]),_c('validation-provider',{attrs:{"name":"Password *","vid":"password","rules":{ required: true, regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/ }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"register-password","state":errors.length > 0 ? false:null,"type":_vm.passwordFieldType,"name":"register-password","placeholder":"Password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v("La password deve essere minimo di 8 caratteri di cui: 1 carattere speciale, 1 numero e 1 carattere Maiuscolo")]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"lg":"6"}},[_c('b-form-group',{attrs:{"label":_vm.selectedRole.value === 'Proprietario' ? 'Regione Sociale *' : 'Regione Sociale',"label-for":"register-regione_sociale"}},[_c('validation-provider',{attrs:{"name":"Regione Sociale","rules":{required: _vm.selectedRole.value === 'Proprietario' ? true : false }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-regione_sociale","name":"register-regione_sociale","state":errors.length > 0 ? false:null,"placeholder":"Regione Sociale"},model:{value:(_vm.regione_sociale),callback:function ($$v) {_vm.regione_sociale=$$v},expression:"regione_sociale"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"6"}},[_c('b-form-group',{attrs:{"label":"Cellulare *","label-for":"register-cellulare"}},[_c('validation-provider',{attrs:{"name":"cellulare","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-cellulare","name":"register-cellulare","state":errors.length > 0 ? false:null,"placeholder":"3801257813"},model:{value:(_vm.cellulare),callback:function ($$v) {_vm.cellulare=$$v},expression:"cellulare"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"6"}},[_c('b-form-group',{attrs:{"label":"Lingua *","label-for":"leanguage-i18n"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.locales,"clearable":false,"label":"nameLocale"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var locale = ref.locale;
var nameLocale = ref.nameLocale;
var img = ref.img;
return [_c('b-img',{attrs:{"src":img,"height":"14px","width":"22px","alt":locale+nameLocale}}),_c('span',[_vm._v(" "+_vm._s(nameLocale))])]}}]),model:{value:(_vm.localeSelected),callback:function ($$v) {_vm.localeSelected=$$v},expression:"localeSelected"}})],1)],1)],1),_c('validation-provider',{attrs:{"name":"Termini e condizioni","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{staticClass:"my-1",class:{ 'text-white': errors[0] },attrs:{"id":"register-privacy-policy","value":"Y","name":"termsandconditions"},model:{value:(_vm.statusTerms),callback:function ($$v) {_vm.statusTerms=$$v},expression:"statusTerms"}},[_vm._v(" Accetto "),_c('b-link',[_vm._v("termini & condizioni ")]),_vm._v("* ")],1)]}}])}),_c('b-form-checkbox',{staticClass:"mb-1",attrs:{"id":"register-marketing-checkbox","name":"marketing","value":"Y","unchecked-value":"N"},model:{value:(_vm.statusMarketing),callback:function ($$v) {_vm.statusMarketing=$$v},expression:"statusMarketing"}},[_vm._v(" Accetto "),_c('b-link',[_vm._v("Profilazione Marketing")])],1),_c('b-button',{attrs:{"variant":"primary","block":"","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_vm._v(" Sign up ")])],1)],1),_c('p',{staticClass:"text-start mt-2"},[_c('span',[_vm._v("* Campi Obbligatori")])]),_c('div',{staticClass:"divider my-2"},[_c('div',{staticClass:"divider-text"},[_vm._v(" oppure ")])]),_c('div',{staticClass:"auth-footer-btn d-flex justify-content-center"},[_c('p',{staticClass:"text-center mt-2"},[_c('span',[_vm._v("Hai già un account?")]),_c('b-link',{attrs:{"to":{name:'auth-login-v2'}}},[_c('span',[_vm._v(" Accedi")])])],1)])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }